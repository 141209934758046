// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-archive-tsx": () => import("./../../../src/pages/archive.tsx" /* webpackChunkName: "component---src-pages-archive-tsx" */),
  "component---src-pages-coloq-tsx": () => import("./../../../src/pages/coloq.tsx" /* webpackChunkName: "component---src-pages-coloq-tsx" */),
  "component---src-pages-comsuk-tsx": () => import("./../../../src/pages/comsuk.tsx" /* webpackChunkName: "component---src-pages-comsuk-tsx" */),
  "component---src-pages-consult-tsx": () => import("./../../../src/pages/consult.tsx" /* webpackChunkName: "component---src-pages-consult-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-covesity-tsx": () => import("./../../../src/pages/covesity.tsx" /* webpackChunkName: "component---src-pages-covesity-tsx" */),
  "component---src-pages-feed-tsx": () => import("./../../../src/pages/feed.tsx" /* webpackChunkName: "component---src-pages-feed-tsx" */),
  "component---src-pages-feedtest-tsx": () => import("./../../../src/pages/feedtest.tsx" /* webpackChunkName: "component---src-pages-feedtest-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-predict-tsx": () => import("./../../../src/pages/predict.tsx" /* webpackChunkName: "component---src-pages-predict-tsx" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../../../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-pages-redasa-tsx": () => import("./../../../src/pages/redasa.tsx" /* webpackChunkName: "component---src-pages-redasa-tsx" */),
  "component---src-pages-remote-tsx": () => import("./../../../src/pages/remote.tsx" /* webpackChunkName: "component---src-pages-remote-tsx" */),
  "component---src-pages-research-tsx": () => import("./../../../src/pages/research.tsx" /* webpackChunkName: "component---src-pages-research-tsx" */),
  "component---src-pages-resources-old-tsx": () => import("./../../../src/pages/resources_old.tsx" /* webpackChunkName: "component---src-pages-resources-old-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-ssafe-data-tsx": () => import("./../../../src/pages/ssafe_data.tsx" /* webpackChunkName: "component---src-pages-ssafe-data-tsx" */),
  "component---src-pages-ssafe-tsx": () => import("./../../../src/pages/ssafe.tsx" /* webpackChunkName: "component---src-pages-ssafe-tsx" */),
  "component---src-pages-strengths-tsx": () => import("./../../../src/pages/strengths.tsx" /* webpackChunkName: "component---src-pages-strengths-tsx" */),
  "component---src-pages-webinar-tsx": () => import("./../../../src/pages/webinar.tsx" /* webpackChunkName: "component---src-pages-webinar-tsx" */),
  "component---src-pages-webinars-tsx": () => import("./../../../src/pages/webinars.tsx" /* webpackChunkName: "component---src-pages-webinars-tsx" */)
}

