import * as React from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'
import logo from '../images/logo.svg'
import { FaCaretDown } from 'react-icons/fa'

const Header = () => {
  const [isBurgerOpen, setIsBurgerOpen] = React.useState(false)
  const closeBurger: void = () => setIsBurgerOpen(false)

  return (

  <header>
    <nav className="navbar is-fixed-top-desktop" role="navigation" aria-label="main navigation">
      <div className="container is-fullhd">

        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <img alt="logo" width="200px" src={logo} style={{marginLeft:'1rem'}} />
          </Link>

          <a
            role="button"
            className={cx('navbar-burger burger', {
              'is-active': isBurgerOpen,
            })}
            aria-label="menu"
            aria-expanded={isBurgerOpen}
            data-target="navbarBasicExample"
            onClick={() => setIsBurgerOpen(open => !open)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div
          id="navbarBasicExample"
          className={cx('navbar-menu', { 'is-active': isBurgerOpen })}
        >
          <div className="navbar-start">
          </div>

          <div className="navbar-end">

            <div  className="navbar-item has-dropdown is-hoverable is-size-5-tablet">

              <Link to="/webinars" onClick={closeBurger} className="navbar-link is-arrowless">
                Academy<FaCaretDown />
              </Link>

              <div className="navbar-dropdown">

                <a className="navbar-item">
                  <Link className="navbar-item" to="/archive" onClick={closeBurger}>
                  Webinar Archive
                  </Link>
                </a>
              </div>

            </div>

              <div  className="navbar-item has-dropdown is-hoverable is-size-5-tablet">
                <Link className="navbar-link is-arrowless" to="/research" onClick={closeBurger}>
                  Research<FaCaretDown />
                </Link>

                <div className="navbar-dropdown">
                <a className="navbar-item">
                    <Link className="navbar-item" to="/coloq" onClick={closeBurger}>
                    ColoQ
                    </Link>
                  </a>
                <a className="navbar-item">
                    <Link className="navbar-item" to="/comsuk" onClick={closeBurger}>
                    COMS-UK
                    </Link>
                  </a>
                  <a className="navbar-item">
                    <Link className="navbar-item" to="/consult" onClick={closeBurger}>
                    CONSULT-19
                    </Link>
                  </a>
                  <a className="navbar-item">
                    <Link className="navbar-item" to="/covesity" onClick={closeBurger}>
                    COVesity
                    </Link>
                  </a>
                  <a className="navbar-item">
                    <Link className="navbar-item" to="/predict" onClick={closeBurger}>
                    PREDICT
                    </Link>
                  </a>
                  <a className="navbar-item">
                    <Link className="navbar-item" to="/redasa" onClick={closeBurger}>
                    REDASA
                    </Link>
                  </a>
                  <a className="navbar-item">
                    <Link className="navbar-item" to="/remote" onClick={closeBurger}>
                    REMOTE
                    </Link>
                    </a>
                  <a className="navbar-item">
                    <Link className="navbar-item" to="/ssafe" onClick={closeBurger}>
                    SSAFE
                    </Link>
                  </a>
                  

                </div>
              </div>




              <Link to="/resources" onClick={closeBurger} className="navbar-item is-size-5-tablet">
                Resources
              </Link>

              <Link to="/strengths" onClick={closeBurger} className="navbar-item is-size-5-tablet">
                Strengths
              </Link>

              <Link to="/about" onClick={closeBurger} className="navbar-item is-size-5-tablet">
               About
              </Link>

              <Link to="/contact" onClick={closeBurger} className="navbar-item is-size-5-tablet">
                Contact
              </Link>

          </div>
        </div>
      </div>
    </nav>
  </header>
  )
}

export default Header
