import * as React from 'react'
import { Link } from 'gatsby'
import imperial_logo from '../images/imperial_logo.svg'
import ighi_logo from '../images/Logo_ighi_white.svg'
import NewsletterForm from './NewsletterForm'

import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaInstagram,
  FaEnvelopeSquare,
  FaEnvelope,
  FaAlignCenter,
  FaYoutube
} from 'react-icons/fa';

const Footer = () => {

return (
  <footer className="footer is-size-6 has-background-grey-dark" id="mailinglist">

  <div className="container">

      <div className="columns is-vcentered">

        <div className="column is-6">

          <p className="subtitle has-text-white">Get updates from PanSurg:</p>
          <NewsletterForm />

        </div>

        <div className="column is-3 is-offset-1 is-flex" style={{justifyContent:'space-around'}}>
          <div className="is-size-2 footer-links is-centered">
                <a href="https://twitter.com/PanSurg">
                  <FaTwitterSquare color="white"/>
                </a>
                &nbsp;
                <a href="https://facebook.com/pansurg">
                  <FaFacebookSquare color="white"/>
                </a>
                &nbsp;
                <a href="https://www.instagram.com/pansurg/">
                  <FaInstagram color="white"/>
                </a>
                &nbsp;
                <a href="https://www.youtube.com/channel/UCsRuddT3ynWEe6tCGTb0_xw/">
                  <FaYoutube color="white"/>
                </a>
                &nbsp;
                <a href="mailto:admin@pansurg.org">
                  <FaEnvelopeSquare color="white"/>
                </a>
          </div>
          </div>
          <div className="column is-2 is-flex footer-menu-links">
            <ul className="footer-links has-text-right has-text-centered-mobile">
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
          </div>

        </div>
        <div className="logos is-flex-tablet has-text-centered">

         <a href="https://www.imperial.ac.uk/"><img alt="imperial logo" width="200px" src={imperial_logo}/></a>
         <a href="https://www.imperial.ac.uk/ighi"><img alt="ighi logo" width="200px" src={ighi_logo}/></a>

      </div>
    </div>
  </footer>
  )
}
export default Footer
