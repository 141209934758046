import React from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import {
  FaEnvelope
} from 'react-icons/fa'
import * as Yup from 'yup'

const NewsLetterForm = () => {

  return (

  <Formik
    initialValues= {{email: ""}}

    validationSchema={Yup.object({
        email: Yup.string()
          .email('Invalid email address')
          .required('Required'),
      })}

    onSubmit= {(values) => {
      addToMailchimp(values.email)
      .then(data => {
        if (data.result === "error") {
            alert("Sorry, something went wrong with that email address");
        } else {
            alert("Thank you for subscribing!");
        }
      })
    }
  }
  >

{() => (
      <Form>
        <div className="field has-addons">

          <div className="control has-icons-left is-expanded">
            <Field
              className="input"
              name="email"
              placeholder="Your email address"
            />
            <span className="icon is-small is-left">
            <FaEnvelope color="grey"/>
            </span>
          </div>

          <button type="submit" className="button is-red">
            Sign Up!
          </button>

        </div>
        <ErrorMessage name="email">{msg => <div className="has-text-white">{msg}</div>}</ErrorMessage>
      </Form>
         )}
    </Formik>
   )
 }

export default NewsLetterForm
