const cookies = require('js-cookie')
const ReactGA = require('react-ga')
const wrapPageElement = require('./src/wrapPage').default

exports.wrapPageElement = wrapPageElement

exports.onRouteUpdate = ({ location }) => {
  // Tracking is delayed to allow for new route to be fully rendered, not
  // sure how necessary it is but I saw it in the source for the analytics
  // plugin and it seemed safe.
  setTimeout(() => {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined

    const consentGiven = cookies.getJSON('cookieConsent')
    if (consentGiven === false) {
      // Extra safety net to ensure we're not tracking folks who have opted out
      // If we don't yet know, these events will go into our manually-created
      // GA queue and either get discarded or sent to GA once we know the
      // consent status.
      // TODO see if we could make this easier with a bootup cookie check in
      // `onClientEntry` instead of initializing tracking in a `useEffect`
      return
    }
    ReactGA.set({ page: pagePath })
    ReactGA.pageview(pagePath)
  }, 50)
}

// Lifted from http://code.iamkate.com/javascript/understanding-the-google-analytics-tracking-code/
function createGaQueue() {
  // store the name of the Analytics object
  window.GoogleAnalyticsObject = 'ga'

  // check whether the Analytics object is defined
  if (!('ga' in window)) {
    // define the Analytics object
    window.ga = function() {
      // add the tasks to the queue
      window.ga.q.push(arguments)
    }

    // create the queue
    window.ga.q = []
  }

  // store the current timestamp
  window.ga.l = new Date().getTime()
}

exports.onClientEntry = () => {
  // Manually create a GA queue so pageviews can be recorded before initializing
  // GA itself. We do that late in order to have a GDPR-compliant implementation
  // that does nothing until consent has been given or we have a cookie indicating
  // prior acceptance.
  createGaQueue()
}
