import * as React from 'react'
import { Link } from 'gatsby'
import cookies from 'js-cookie'
import * as ReactGA from 'react-ga'

interface CookieOptions {
  expires: number
}

function startTracking() {
  ReactGA.initialize('UA-161060039-1', {
    debug: true,
    gaOptions: {
      siteSpeedSampleRate: 100,
    },
  })
  ReactGA.set({ anonymizeIp: true })
}

const Tracking = () => {
  const [isVisible, setIsVisible] = React.useState(false)

  const storeConsentCookie = (value: boolean, options?: CookieOptions) => {
    setIsVisible(false)
    cookies.set('cookieConsent', value, options)
  }

  // If we get explicit acceptance or rejection, remember it for 'ever'
  const onReject = () => storeConsentCookie(false, { expires: 365 })
  const onAccept = () => {
    storeConsentCookie(true, { expires: 365 })
    startTracking()
  }

  // If the prompt is just dismissed, don't show it again for the session
  // so the user will be asked again next visit
  const onDismiss = () => storeConsentCookie(false)

  React.useEffect(() => {
    const consentGiven = cookies.getJSON('cookieConsent')
    if (consentGiven === undefined) {
      setIsVisible(true)
    }

    if (consentGiven === false) {
      // Do not track
      console.log('Disabling GA due to user opt-out')
      // Replace the GA queue with a no-op
      // We manually create a queue while waiting to see if we can load the
      // real GA lib, so this will discard any queued events.
      window.ga = () => {}
    }

    if (consentGiven === true) {
      startTracking()
    }
  }, [
    /* Should only init GA once */
  ])

  if (!isVisible) {
    return null
  }

  return (
    <div className="cookie-notification">
      <div className="notification is-info">
        <button className="delete" onClick={onDismiss}></button>
        This website uses cookies, and also collects some information using
        Google Analytics. See our <Link to="/privacy-policy">privacy policy</Link>
        for more information.
        <div className="buttons is-right">
          <button className="button is-success" onClick={onAccept}>
            Accept
          </button>
          <button className="button is-text" onClick={onReject}>
            Reject
          </button>
        </div>
      </div>
    </div>
  )
}

export default Tracking
