module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"title":"PanSurg Collaborative","titleTemplate":"PanSurg Collaborative","description":"Collaboration, research, data and discussion to optimise the frontline delivery of surgical care in real-time during the COVID pandemic and beyond.","type":"website","locale":"en_GB","url":"https://www.pansurg.org","site_name":"PanSurg","canonical":"https://www.pansurg.org","images":[{"url":"https://www.pansurg.org/logo.jpg","width":800,"height":600,"alt":"pansurg logo"}]},"twitter":{"handle":"@PanSurg","site":"@PanSurg","cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/opt/build/repo/src/components/mdxLayout.tsx"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-external-links","options":{}},{"resolve":"gatsby-remark-autolink-headers","options":{"icon":false}},{"resolve":"gatsby-remark-smartypants"},{"resolve":"gatsby-remark-images","options":{"maxWidth":1200}}],"remarkPlugins":[null],"extensions":[".mdx"],"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"PanSurg","short_name":"PanSurg","icon":"src/images/icon.svg","start_url":"/","background_color":"#ffffff","theme_color":"#00407B","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c80d4dd23f37eb37d6a232f2569a7cb8"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
