import * as React from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import Tracking from './components/Tracking'
import './pages/mystyles.scss'
import SEO from './components/seo'
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { Helmet } from 'react-helmet'
import { MedicalOrganization } from "schema-dts";
import { JsonLd } from "react-schemaorg";

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <GatsbySeo />
          <JsonLd<MedicalOrganization>
    item={{
  "@context": "https://schema.org",
  "@type": "MedicalOrganization",
  name: "Pansurg",
  alternateName: "PanSurg Collaborative",
  url: "https://www.pansurg.org",
  logo: "https://www.pansurg.org/logo.jpg",
}}/>
      <div className="wrapper">
        <a id="top" />
        <Tracking />
        <Header />
        <main>
        {children}
        </main>
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default Layout
